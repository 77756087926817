<template>
    <div class="button-list">
        <div class="is-center"><center><a href="/"><img src="https://www.macsni.org/assets/uploads/macs-logo.png" /></a></center><br /></div>
        <br />

         <div class="notification is-warning" v-if="loading===true">
            Loading your profile details ...
        </div>

        <div v-if="loading===false">

            <div v-if="volunteer.agreement_signed == 0">
                <div class="notification is-link">
                
                <p>It looks like you haven't agreed to the details in the MACS Volunteer Agreement. We may have updated these if you had previously agreed. You must do this before continuing to use this app.</p>
                <br />
                <p><router-link to="/agreement">Read the Volunteer Agreement now</router-link></p>
                </div>
                <br />
            </div>
            
            <div v-if="volunteer.agreement_signed == 1">
                <router-link to="/matches/"><button class="button is-large is-fullwidth is-danger">Matches</button></router-link>
                
                <router-link to="/personal_cv/" ><button class="button is-large is-fullwidth is-danger">Personal CV</button></router-link>
            

                <router-link to="/personal_details/" ><button class="button is-large is-fullwidth is-danger">Personal Details</button></router-link>
                
                <router-link to="/help/" ><button class="button is-large is-fullwidth is-danger">Help and Support</button></router-link>
            </div>
        </div>

         <a href="https://sway.office.com/9klHkz4ahX8nkYeH" target="_blank"><button class="button is-large is-fullwidth is-danger">Volunteer Handbook</button></a>
        

        <button v-if="$auth.isAuthenticated" class="button is-large is-fullwidth is-warning" @click="logout">Logout</button>
        <br />
    </div>
</template>

<script>
import VolunteerService from '@/services/VolunteerService.js';

export default {
  name: 'ContactDetails',
  data: function() {
    return {
      volunteer: [],
      access_token: '',
      loading:true,
      error: false
    }
  },
  created() {
    this.getAccessToken()
      .then(() => { 
          this.getVolunteerDetails();
      });
    
    
  },
  methods: {
    async getVolunteerDetails() {
        this.loading = true
        this.error = false
        
        VolunteerService.getVolunteerDetails(this.accessToken, "myself")
        .then(
            (volunteer => {
            this.$set(this, "volunteer", volunteer);
            this.loading = false
            }).bind(this)
        ).catch((error) => {
            if(error.response.status === 404)
            {
                //alert("This details cannot be found or you are not authorised to see this information")
            }
            this.loading = false
            this.error = true
        });
    },
    logout() {
        if(confirm("Are you sure you want to logout ?"))
        {
            this.$auth.logout({
                returnTo: window.location.origin
            });
        }
    }
    
  }
}
</script>


<style scoped>
.section {padding: 1.5rem 1.5rem;}
.button-list a button {margin-bottom:20px}

</style>
